<template>
  <section class="orders">
    <div class="page-header">
      <h3 class="page-title"> Facture </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">E-commerce</a></li>
          <li class="breadcrumb-item active" aria-current="page">Facture</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Facture</h4>
            <div class="row">
              <div class="col-md-4 offset-md-8">
                <b-input-group>
                      <b-form-input placeholder="Recherche" v-model="search"></b-form-input>
                </b-input-group>
              </div>
            </div>
            <div class="row overflow-auto">
              <div class="col-12">
                <b-table :items="items" id="table-list" responsive 
                :per-page="perPage" 
                :current-page="currentPage" 
                :fields="fields" 
                :sort-by.sync="sortBy" 
                :sort-desc.sync="sortDesc"
                :filter="search">
                
                  <template #cell(status)=row>
                    <span class="badge badge-danger" v-if="row.item.status==='cancelled'">Annulée</span>
                    <span class="badge badge-success" v-if="row.item.status==='delivered'">Délivrée</span>
                  </template>

                  <template #cell(action)="row" >
                    <button  class="btn btn-outline-primary px-4 py-1" @click="invoiceDetails(row)">
                      <a style="color:inherit">
                      <i class="mdi mdi-eye-outline text-primary mr-2"></i>Voir</a>
                    </button>
                  </template>

                </b-table>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="table-list"
                  align="right">
                </b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script src="https://cdn.jsdelivr.net/npm/axios/dist/axios.min.js"></script>
<script>
const axios = require ('axios').default;
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
});

export default {
  data: function() {
    return {
      perPage: 10,
      sortBy: 'name',
        currentPage: 1,
        sortDesc: false,
        sortByFormatted: true,
        filterByFormatted: true,
        sortable: true,
        search:'',
        fields: [
          // { key: 'ulid', sortable: true},
          // { key: 'ulidpurchaseorder', sortable: true},
          { key: 'slugname', sortable: true},
          { key: 'date', sortable: true},
          { key: 'status', sortable: true},
          { key: 'action', sortable:false}
          // 'action'
        ],
        items:[
        ]
    };
  },
  computed: {
      rows() {
        return this.items.length
      },
    },
  methods: {
    getBills(){
      axios.get('bills')
    .then(resBills=> {
      this.items=resBills.data.result
    })
    .catch(errBills=> console.log(errBills))
    },
    invoiceDetails (row) {
      this.$router.push({
        name: "detailsFacture",
        params: { 
          detailsFacture:row,
          ulid: row.item.ulid
          },
        })
    }
  },
  async created () {
    this.getBills()
  },
  mounted (){
    // axios.get('https://sites.comolapapaya.com/bills', {withCredentials:true})
    // .then (res => {
    //   this.items=res.data.result
    // })
    // .catch(err=>{
    //   console.log('err_bills',err)
    // })
  }
  
}
</script>